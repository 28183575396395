@import '../../layout/theme/mixins.scss';

.formControl {
    max-width: 423.01px;

    @include for-desktop-up {
        width: 100%;
        max-width: none;
    }
}

.formBtn {
    width: 157px;

    @include for-tablets-up {
        width: 133.62px;
    }
}

.title {
    padding: 5px;
    @include for-tablets-up {
        width: 133.62px;
    }
}
.string {
    padding: 5px;
    width: fit-content;
    @include for-tablets-up {
        width: 133.62px;
    }
}
