@import '../../../../theme//variables.module.scss';
@import '../../../../theme/mixins.scss';

.profile {
    display: flex;
    align-items: center;

    @include for-tablets-up {
        margin-top: 32px;
    }

    &.isSidemenuOpen {
        @include for-tablets-up {
            display: none;
        }
    }

    .currentUser {
        color: white;
        margin-right: 16px;
        font-size: 22px;
    }
}
