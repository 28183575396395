.mb-dialog {
    font-family: Montserrat;
    font-size: 16px;
    padding: '30px';

    .mb-close-icon {
        position: absolute;
        right: 8px;
        top: 8px;
        color: #000;
    }

    .mb-dialog-content {
        min-height: 265px;
        max-height: 350px;
        min-width: 425px;
        max-width: 500px;
        padding: 0;
    }

    .mb-simple-alert-dialog-content {
        min-width: 425px;
        max-width: 500px;
        padding: 0;
    }

    .mb-dialog-content-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 28px;
    }

    .mb-title {
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        color: #2c2f33;
        margin: 20px 0;
    }

    .mb-content {
        overflow-wrap: break-word;
        color: #2c2f33;
        margin: 10px 30px;
        text-align: center;
        white-space: pre-line;
    }

    .mb-simple-alert-content {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        color: #2c2f33;
        margin: 20px 20px;
    }

    .mb-dialog-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: space-around;
        justify-content: center;
        border-top: 1px solid #cedbdc;
        padding-top: 25px;
        margin: 20px 40px;
    }

    .mb-dialog-button {
        height: 48px;
        font-size: 16px;
    }
}
