@import '../../../../layout/theme/mixins.scss';

.subCategory {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 24px;
    color: $grayLighter;

    @include for-tablets-up {
        width: 100%;
        flex-direction: row;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        /*  &::-webkit-scrollbar {
            height: 3px;
        }

        &::-webkit-scrollbar-track {
            background: $background-color;
        }

        &::-webkit-scrollbar-thumb {
            background: #bebebe;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        } */
    }

    span {
        padding: 10px 35px;
        cursor: pointer;

        &.active {
            color: white;
            font-weight: 900;
        }
    }
}
