.scrollbarContainner {
    overflow: auto;
    direction: ltr;
    flex: 1;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #8a8a8a;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .innerScrollbar {
        direction: rtl;
    }
}
