@import '../../../../theme/variables.module.scss';
@import '../../../../theme/mixins.scss';

.sideMenu {
    width: 100%;
    padding: 0;
    margin: 5vh 0 !important;

    .navLink {
        display: inherit;
        padding: 24px 0;
        text-decoration: none;
        border-bottom: 1px solid $grayDark;

        @include for-tablets-up {
            &:first-child {
                border-top: 1px solid $grayDark;
            }
        }

        .navLinkData {
            display: flex;
            flex-direction: row;
            color: $white;
            align-items: center;

            &.active {
                color: $azure;

                .navLinkSelectedRec {
                    margin-left: 12px;

                    @include for-tablets-up {
                        display: none;
                    }
                }
            }

            .listItemText {
                font-size: 28px !important;
            }
        }
    }
}
