@import './../../../../../../layout/theme/mixins.scss';

.title {
    font-weight: 400;
    font-size: 30px;
    line-height: 46px;
    color: $labelInput;
    justify-content: center;
    display: flex;
}

// .footer {
//     display: contents;
// }

.active {
    color: aliceblue;
}
.noActive {
    color: grey;
}

.imageContainer {
    display: 'flex';
    flex-grow: 1;
    border: '1px solid grey';
    border-radius: '15px';
    padding: '5px';
    margin: '5px 5px';
}

.imgPreview {
    width: 50px;
    height: 50px;
}
