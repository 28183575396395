@import '../../layout/theme/mixins.scss';

.userDetails {
    .header {
        display: flex;
        align-items: center;
        gap: 34px;
        margin-bottom: 32px;

        @include for-laptops-up {
            margin-bottom: 58px;
            flex-direction: column;
        }

        .avatar {
            width: 128px;
            height: 128px;
        }

        .title {
            display: flex;
            flex-direction: column;
            gap: 8px;

            @include for-laptops-up {
                align-items: center;
            }

            .name {
                font-weight: 400;
                font-size: 32.2673px;
                color: $labelInput;
            }
            .role {
                font-weight: 400;
                font-size: 15px;
                color: $white;
            }
        }
    }

    .section {
        margin-bottom: 16px;

        .description {
            font-weight: 400;
            font-size: 21.9792px;
            color: $grayLighter;
            margin-left: 56px;
        }

        .data {
            font-weight: 400;
            font-size: 21.9792px;
            color: $labelInput;
        }
    }
}

.accordion {
    .accordionSummary {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 400;
            font-size: 30px;
            color: #ffffff;
        }

        .subTitle {
            font-weight: 400;
            font-size: 20px;
            color: $grayLighter;
        }
    }
}
