@import '../../layout/theme/mixins.scss';

.toggleButtonGroup {
    flex-direction: row-reverse;

    .toggleButton {
        color: $textInput !important;
        background-color: $white !important;
        padding: 4px;
    }
    .selectedToggleButton {
        color: #247cd4 !important;
        background-color: #edf4fb !important;
        font-weight: bolder;
    }
}
