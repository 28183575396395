@import '../../../../theme/mixins.scss';

.header {
    height: 100px;

    @include for-tablets-up {
        background-color: $black !important;
    }

    .toolbar {
        height: 100%;
        justify-content: space-between;
        padding: 0 56px 0 83px !important;

        @include for-tablets-up {
            padding: 0 18px 0 22px !important;
        }

        .logoSide {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            gap: 10px;
        }
    }
}
