.switchContainner {
    .titleContainner {
        display: flex;
        flex-direction: column;
        .title {
            font-weight: 400;
            font-size: 20.3989px;
            line-height: 30px;
        }
        .subTitle {
            font-weight: 400;
            font-size: 15px;
            color: #bebebe;
        }
    }
}
