@import '../../layout/theme/variables.module.scss';

.root {
    border-radius: 70px !important;
    padding: 8px !important;

    &.text {
        color: $labelInput !important;
    }

    &.outlined {
        background-color: $transparent !important;
        color: $labelInput !important;
        border: 1px solid $labelInput;
    }

    &.contained {
        background-color: $labelInput !important;
        color: $black !important;
    }

    &:disabled {
        background-color: $grayLighter !important;
        color: $backgroundInput !important;
    }

    &.active {
        background-color: $transparent !important;
        color: $grayLighter !important;
    }
}
