@import '../../theme/variables.module.scss';

.entitiesPageLayout {
    width: 100%;
    min-height: 100vh;
    position: relative;

    .listComponentContainner {
        padding-top: 100px;
        position: fixed;
        width: 32%;
        background-color: $black;

        &.listIsListMode {
            width: 100%;
            background-color: $background-color;
        }

        &.listIsFormMode {
            display: none;
        }
    }

    .formComponentContainner {
        min-height: calc(100vh - 150px);
        padding: 100px 166px 50px 166px;
        background-color: $background-color;
        margin-right: 32%;

        &.formIsListMode {
            display: none;
        }

        &.formIsFormMode {
            min-height: calc(100vh - 210px);
            width: 95%;
            margin: 0;
            padding: 110px 2.5% 100px 2.5%;
        }
    }
}
