@import '../../layout/theme/mixins.scss';


.message-list{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: absolute;
    bottom: 10%;
    left: 1%;
    z-index: 999;
    @include for-desktop-up{
        align-items: initial;
        width: 100%;
        bottom: 18%;
        left: auto;
    }

    .message-container{
        display: flex;
        flex-direction: column;
        margin: 5px 0;
        &.slide-out {
            animation: slide-out 0.5s ease-in-out forwards;
          }
    }
    
}

@keyframes slide-out {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    90% {
      opacity: 0;
      transform: translateX(-90%);
    }
  }






  