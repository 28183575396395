@import '../../layout/theme/mixins.scss';

.footer {
    height: 92px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    background-color: $footer;

    @include for-desktop-up {
        flex-direction: column-reverse;
        height: auto;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-desktop-up {
            width: 100%;
            height: 90px;
            background-color: $black;
        }
        .hdmiBtn {
            height: 29px;
            display: flex;
            flex-direction: column;
            border-left: 4px solid $white !important;
            padding: 0 8px !important;
            margin: 0 20px !important;

            &.active {
                border-left: 4px solid #6bb8f3 !important;
            }
        }

        .details {
            margin: 0 10px;
            .owner {
                color: $white;
                font-weight: 700;
                font-size: 24px;
                line-height: 13px;
                cursor: pointer;
            }

            .channel {
                margin-top: 8px;
                .channelName {
                    color: #bdbdbe;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 12px;
                    margin-left: 11px;
                }

                .indicator {
                    color: $azure;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 25px;
                }
            }
        }
    }
    .actions {
        display: flex;
        align-items: center;
        gap: 30px;

        @include for-desktop-up {
            width: 100%;
            height: 68px;
            justify-content: space-around;
            gap: 0;
            overflow-x: auto;
        }
    }
}
