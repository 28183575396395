@import '../../layout/theme/mixins.scss';

.dialogBody {
    display: flex;
    flex-direction: column;
    width: 40vw;
    min-width: 500px;
    height: 67vh;

    @include for-tablets-up {
        width: 100%;
        min-width: auto;
        height: calc(100vh - 20px);
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
    }

    .footer {
        display: flex;
        justify-content: space-evenly;

        & > * {
            flex: 0.4;
        }
    }
}
