@import '../../../../layout/theme/mixins.scss';

.hdmiContainner {
    display: flex;
    height: 100%;

    @include for-desktop-up {
        width: 100%;
        height: 68px;
        justify-content: space-around;
    }

    .hdmi {
        width: 158px;
        height: 100%;
        text-align: center;
        border: 1px solid #272727;
        border-top: 0;
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        @media (max-width: 1500px) {
            width: 120px;
        }

        @include for-desktop-up {
            width: 100%;
        }

        &.selected {
            position: relative;
            color: #6bb8f3;
            background: linear-gradient(90deg, #232323 0%, #292929 45.41%, #222222 100%);

            .selectedRect {
                position: absolute;
                width: 4px;
                height: 29px;
                background: #6bb8f3;
                right: 0;
            }
        }
    }
}
