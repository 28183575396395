@import '../../theme/variables.module.scss';

.mainLayout {
    /*     background-image: url('../../../assets/kan.svg');*/
    height: 100%;
    min-height: 100vh;
    // background-image: linear-gradient($transparent 0, $background-color 38vw),
    //     url('../../../assets/kan.jpg');
    background-image: linear-gradient($transparent 0, $background-color 38vw),
        url('https://iptvdev.blob.core.windows.net/background/kan.jpeg');
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 100%;
}
