@import '../../layout/theme/mixins.scss';

.listContainer {
    overflow-y: auto;
    direction: ltr;

    .list {
        direction: rtl;

        .listItemContainer {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #363739;

            .listItem {
                display: flex;
                min-height: 80px;
                justify-content: space-between;
                align-items: center;
                padding-left: 0;
                padding-right: 25px;
                cursor: pointer;

                &.selectedListItem {
                    padding-right: 19px;
                }

                .listItemRight {
                    display: flex;
                    align-items: center;
                    .titleContainer {
                        .title {
                            color: $activeText;
                        }
                        .subTitle {
                            color: $textInput;
                        }
                    }
                }

                .listItemLeft {
                    .img {
                        height: 74px;
                        width: 112px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
