@import '../../layout/theme/mixins.scss';

.mainConatainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    overflow: hidden;
    @include for-tablets-up {
        position: static;
        transform: translateY(0);
        flex-direction: column;
        min-height: calc(100vh - 100px);
        padding-top: 100px;
    }
}
