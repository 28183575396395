@import '../../../../../layout/theme/mixins.scss';

.titleContainner {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin: 0 0 5px 0;

    .title {
        font-weight: 400;
        font-size: 30px;
        line-height: 46px;
    }
    .subTitle {
        color: $grayLighter;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
    }
}

.actions {
    display: flex;
    gap: 28px;
}
