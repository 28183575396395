@import '../../../../theme/mixins.scss';

.infoSectionContainer {
    height: 100px;
    display: flex;
    align-items: center;

    @include for-tablets-up {
        height: unset;
        flex-direction: column;
        align-items: unset;
    }
}
