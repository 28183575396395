@import '../../../../layout/theme/mixins.scss';

.titleContainer {
    display: flex;
    align-items: center;
    gap: 16px;

    .title {
        font-weight: 400;
        font-size: 30px;
        color: $labelInput;
    }

    .selectedCount {
        font-weight: 400;
        font-size: 20.3989px;
        color: #adadad;
    }
}

.genericListContainer {
    margin: 55px 0 51px 0;
}
