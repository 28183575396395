@import '../../../../theme/mixins.scss';

.sideMenuControllers {
    width: 23px;
    margin-left: 40px;
    padding: 0 !important;

    .keyboardArrowRightIcon {
        width: 32px;
        height: 32px;
    }

    @include for-tablets-up {
        height: 100px;
    }
}
