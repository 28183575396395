@import '../../layout/theme/mixins.scss';
.popupmessage {
    // position: absolute;
    display: flex;
    border: 0.5px rgb(187, 2, 2) solid;
    border-radius: 15px;
    background: linear-gradient(180deg, #45464f 0%, #2d2f36 100%);
    height: 10vmin;
    width: 45vmin;
    overflow: auto;
    @include for-desktop-up {
        height: auto;
        width: auto;
        margin: 1vmin;
        padding: 2vmin;
    }

    .box {
        width: 100% !important;
        font-size: 18px;
        display: flex;
        margin: 5px;
        align-items: center;
        height: auto;
    }
    .button {
        height: auto;
        align-self: center;
        color: white;
    }

}


