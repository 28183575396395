@import './layout/theme/variables.module.scss';

body {
    margin: 0;
    direction: rtl;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'SimplerPro_V3';
    color: $white;
}

.form {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

#root {
    height: 100vh;
}

@font-face {
    font-family: 'SimplerPro_V3';
    src: local('SimplerPro_V3'),
        url('./assets/fonts/SimplerPro_V3-Regular.otf') format('truetype');
}

.bodyToast {
    background-color: #363636;
}

.toastMain {
    background-color: #363636;
}
