@import '../../layout/theme/variables.module.scss';

.activeMode {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 80px;
    color: $textInput;

    .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $textInput;
    }

    &.active {
        color: $activeText;

        .dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $activeDot;
        }
    }
}
