//colors
$background-color: #242629;
$black: #000000;
$white: #ffffff;
$whiteOpacity: #ffffff33;
$grayDark: #18191b;
$gray: #232323;
$grayLight: #3f4043;
$grayLighter: #bebebe;
$azure: #50b2ee;
$transparent: transparent;

$labelInput: #f9f9f9;
$textInput: #8a8a8a;
$backgroundInput: #414046;
$activeText: #b5b5b5;
$activeDot: #6bf391;

$footer: #363636;

// media query breackpoints
$breackpoint-phone: 576px;
$breackpoint-tablets: 768px;
$breackpoint-laptops: 992px;
$breackpoint-desktop: 1200px;

:export {
    backgroundColor: $background-color;
    black: $black;
    white: $white;
    whiteOpacity: $whiteOpacity;
    grayDark: $grayDark;
    gray: $gray;
    grayLight: $grayLight;
    grayLighter: $grayLighter;
    azure: $azure;
    transparent: $transparent;
    footer: $footer;

    labelInput: $labelInput;
    textInput: $textInput;
    backgroundInput: $backgroundInput;
    activeText: $activeText;
    activeDot: $activeDot;

    breackpoint-phone: $breackpoint-phone;
    breackpoint-tablets: $breackpoint-tablets;
    breackpoint-laptops: $breackpoint-laptops;
    breackpoint-desktop: $breackpoint-desktop;
}
