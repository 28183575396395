@import '../../../../layout/theme/mixins.scss';

.module {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 29.18px;
    color: $grayLighter;
    margin-bottom: 21px;

    @include for-tablets-up {
        width: 100%;
        background-color: $black !important;
        flex-direction: row;
        justify-content: space-evenly;
    }

    span {
        padding: 1px 6px 1px 0;
        margin: 10px 29px 10px 0;
        cursor: pointer;

        &.active {
            border-right: 6px solid #50b8f9;
            margin-right: 23px;
            color: #6bb8f3;

            @include for-tablets-up {
                border-right: 0;
                border-bottom: 3px solid #50b8f9;
            }
        }
    }
}
