@import '../../layout/theme/mixins.scss';

$active-slide-desctopp-height: 35vmin;
$active-slide-tablets-height: 65vmin;

.swiper {
    width: 80vw;
    margin: 0 auto 0 0;
    height: $active-slide-desctopp-height;

    @include for-tablets-up {
        width: 100vw;
        height: $active-slide-tablets-height;
    }

    .swiper-wrapper {
        align-items: center;
        left: 25%;

        @include for-tablets-up {
            left: -3%;
        }

        .swiper-slide {
            width: 18.5vmin !important;
            height: auto !important;
            flex: 0 0 auto !important;
            cursor: pointer;

            @include for-tablets-up {
                width: calc(50vmin * 0.8) !important;
            }

            .innerSwiperSlideContainer {
                width: 100% !important;
                height: 23.5vmin;
                font-size: 18px;
                display: flex;
                margin: 0px;
                background: linear-gradient(180deg, #45464f 0%, #2d2f36 100%),
                    linear-gradient(0deg, #5a5c68, #5a5c68);
                border: 3px solid #5a5c68;
                box-shadow: 0px 8px 16px #000000, 4px 4px 27px 9px rgba(0, 0, 0, 0.07),
                    inset 0px 12px 50px rgba(255, 255, 255, 0.1),
                    inset 4px 4px 49px 12px rgba(255, 255, 255, 0.05);

                img {
                    width: 100%;
                    margin: auto;
                    display: block;
                }

                @include for-tablets-up {
                    height: 50vmin;
                }
            }
            &.selectedChannel {
                width: 47vmin !important;

                @include for-tablets-up {
                    width: calc(65vmin * 0.8) !important;
                }

                .innerSwiperSlideContainer {
                    height: $active-slide-desctopp-height;
                    // border: 3px solid #ffffff;
                    box-shadow: inset -1px -1px 20px rgba(0, 0, 0, 0.2);
                    filter: drop-shadow(11px 8px 10px rgba(0, 0, 0, 0.25));

                    @include for-tablets-up {
                        height: $active-slide-tablets-height !important;
                    }

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: $white;
    }
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 100%;
    }
}
