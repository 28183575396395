@import '../../../layout/theme/mixins.scss';

.tabs {
    display: flex;
    flex-direction: column;

    @include for-tablets-up {
        width: 100%;
    }
}
