@import '../../../../theme/variables.module.scss';
@import '../../../../theme/mixins.scss';

@keyframes min-width {
    from {
        min-width: 0;
    }
    to {
        min-width: 550px;
    }
}

.menuDrawer {
    .paper {
        width: 0 !important;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10000;
        border: 0;
        align-items: start;
        overflow-x: hidden;
        white-space: nowrap;
        background-color: $transparent;
        background-image: linear-gradient(270deg, $black 59.68%, $transparent 100%);
        color: $black;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        box-sizing: border-box;

        @include for-tablets-up {
            background-color: $black;
            background-image: none;
        }

        &.open {
            width: 45vw !important;
            min-width: 550px;
            animation-name: min-width;
            animation-duration: 225ms;
            transition: width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

            @include for-tablets-up {
                min-width: 0;
                width: 100vw !important;
            }
        }

        .innerPaperContainer {
            width: 60%;
            height: 100%;

            @include for-tablets-up {
                width: 100%;
            }

            .menuContentContainer {
                position: relative;
                height: 100%;
                margin: auto;
                margin: 0 29px !important;
                display: flex;
                flex-direction: column;

                & > * {
                    padding: 0 29px !important;
                }

                @include for-tablets-up {
                    margin: 0 11px !important;

                    & > * {
                        padding: 0 11px !important;
                    }
                }

                .kan11Logo {
                    display: none;

                    @include for-tablets-up {
                        display: block;
                        position: absolute;
                        top: 36px;
                        right: 50%;
                        transform: translateX(50%);
                    }
                }

                .versionDiv {
                    color: $white;
                    font-size: 10px;
                    padding-top: 20px !important;
                    padding-bottom: 20px !important;
                    text-align: center;
                }
            }
        }
    }
}
