@import '../../layout/theme/mixins.scss';

.section {
    &::before {
        display: none;
    }
    .sectionHeader {
        border-bottom: 1px solid #363739;

        .sectionHeaderContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
        }
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
