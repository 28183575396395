@import '../../../../layout/theme/mixins.scss';

.uploadFileButton {
    display: flex;
    flex-direction: column;

    .img {
        width: 316.68px;
        height: 191px;
        object-fit: cover;
    }

    .uploadFileButton {
        text-decoration: underline;
        font-style: normal;
        font-weight: 400;
        font-size: 16.69px;
        line-height: 32px;
        color: $textInput;
    }
}

.uploadFileLink {
    display: none;
}
