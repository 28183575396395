@import '../../layout/theme/variables.module.scss';

.inputFilter {
    width: 100%;
    border: 0.62029px solid #a3a3a3 !important;
    border-radius: 50px !important;

    .input {
        background-color: $transparent;
    }
}
