.entityFormActionsMobile {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: #242629;

    & > * {
        flex: 1;
        margin: 0 2.5% !important;
    }
}
