@import './variables.module.scss';

@mixin for-phone-up {
    @media (max-width: $breackpoint-phone) {
        @content;
    }
}
@mixin for-tablets-up {
    @media (max-width: $breackpoint-tablets) {
        @content;
    }
}
@mixin for-laptops-up {
    @media (max-width: $breackpoint-laptops) {
        @content;
    }
}
@mixin for-desktop-up {
    @media (max-width: $breackpoint-desktop) {
        @content;
    }
}
